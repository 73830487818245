import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Auth from "./Authentication/auth";
import "./index.scss";
import Loader from "./shade/Loaders/Loaders"
const App = React.lazy(() => import("../src/shade/layouts/App"));
const Switcherapp = React.lazy(() => import("../src/shade/layouts/Switcherapp"));
const Custompages = React.lazy(() => import("../src/shade/layouts/custompages"));
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));

const UserLookUp = React.lazy(() => import("./components/Pages/UserLookUp"));
const EffectiveTokens = React.lazy(() => import("./components/Pages/EffectiveTokens"));
const Explorer = React.lazy(() => import("./components/Pages/Explorer"));

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"))
//Form
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
  <BrowserRouter>
    <React.Suspense fallback={<Loader/>}>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}/`} element={<Auth />}>
          <Route index element={<AuthLogin />} />
          
          <Route
            path={`${process.env.PUBLIC_URL}/authentication/login`}
            element={<AuthLogin />}
          />
            <Route
            path={`${process.env.PUBLIC_URL}/authentication/signup`}
            element={<AuthSignup />}
          />
        </Route> 
          
        <Route path={`${process.env.PUBLIC_URL}/`} element={<App />}>
          <Route
              path={`${process.env.PUBLIC_URL}/`}
              element={<Dashboard />}
          />
          <Route>
            <Route
              path={`${process.env.PUBLIC_URL}/dashboard`}
              element={<Dashboard />}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/user-lookup`}
              element={<UserLookUp />}
            />
            
            <Route
              path={`${process.env.PUBLIC_URL}/explorer`}
              element={<Explorer />}
            />
            
            <Route
              path={`${process.env.PUBLIC_URL}/effective-tokens`}
              element={<EffectiveTokens />}
            />

          </Route>
        </Route>
      </Routes>
    </React.Suspense>
  </BrowserRouter>
</React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
